import axios from "axios";
import React, { useEffect, useState } from "react";
import { Bounce, Hinge, Slide,AttentionSeeker } from "react-awesome-reveal";
import { Link, useLocation } from "react-router-dom";
import image from "../../../assets/image/imgtest/image";
import {
  file_url,
  list4moduleHateaosByLangue,
  moduleWithRattingLessonQuestion,
  modulequestionrep,
} from "../../../service/config";
import Carrousel from "./Carrousel";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import useIntersectionObserverWithProps from "../../../outils/ObserverApparitionPropsChange";
import LoadingCard from "./LoadingCard";
import { useLanguage } from "../../../service/langue/LanguageProvider";
export default function Section3(props) {
  const myref = useRef(null)
  const {pathname} = useLocation()
  const {dataRedux,load,sectionRef}  = useIntersectionObserverWithProps(moduleWithRattingLessonQuestion,'exomod','addexomod','data');
const {translations} = useLanguage()

  // constcons getModules = async () => {
  //  if (dataRedux.length == 0) {
  //   setLoad(true);
  //   try {
  //     await axios
  //       .get(list4moduleHateaosByLangue + localStorage.getItem("langue"))
  //       .then((response) => {
  //         console.log(response);
  //         const data = response.data.data;
  //         setModules(data);
  //         dispatch({type:'addexomod',payload:data})
  //         setLoad(false);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  //  }else{
  //   setLoad(false)
  //   try {
  //     await axios
  //       .get(list4moduleHateaosByLangue + localStorage.getItem("langue"))
  //       .then((response) => {
  //         console.log(response);
  //         const data = response.data.data;
  //         setModules(data);
  //         dispatch({type:'addexomod',payload:data})
  //         setLoad(false);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  //  }
  // };
  // useEffect(() => {
  //  let url =  pathname.split('/')
  //   getModules();
  
  // }, [props.test]);
  return (
    <div id="exercice"  className=" container-lg container-fluid my-2">
      {/* <div className="my-4 mb-5 d-flex gap-3">
        <div className="vr w-1 bg-primary"></div>
        <p className="h1">Faire des exercices</p>
      </div> */}
      <div className="titre text-center  pt-3" ref={myref}>
      <AttentionSeeker triggerOnce><p className="h1"> <div className="vr w-2 bg-primary me-3"></div> {translations?.theoreticalExercises} <div className="ms-3 vr w-2 bg-primary"></div></p></AttentionSeeker> 
      </div>
      <div className="">
        {
            <>
            <div className="row justify-content-center" id="module">
              <div className="col-12 ">
                <div className="mt-3 p-2" ref={sectionRef}>
                  <h2 className="text-start">
                    <div className="vr w-1 bg-primary"></div> {translations?.byModules}
                  </h2>
                  <p className="my-3" style={{ textAlign: "justify" }}>
                  {translations?.byModulesDescription}
                  </p>
                </div>
              </div>
              <div className="col-12 ">
               {load ? 
         <LoadingCard/>
         : load==false && dataRedux.length == 0 ?  <div className="text-center display-6 my-5">Pas d'exercice à afficher</div> : <Carrousel type="exe" data={dataRedux.filter((el,i)=> el.num_questionnaires != null)} load={load} />}
              </div>
            </div>
            <div className="row justify-content-center my-4" id="categ">
              <div className="col-12">
                <div className="mt-3 p-2">
                  <h2 className="text-start">
                    <div className="vr w-1 bg-primary"></div> {translations?.byCategory}
                  </h2>
                  <p className="my-3 " style={{ textAlign: "justify" }}>
                  {translations?.byCategoryDescription}
                  </p>
                </div>
              </div>
              <div className="col-12 ">
              {props.categ.length == 0?  <LoadingCard/> : <Carrousel type="categ" data={props.categ} load={load} />}
              </div>
            </div>
          </>
         
        }
      </div>
    </div>
  );
}
